<template>
  <div class="text-limit-row-show">
    <input id="checkBox" class="check_box" type="checkbox" v-model="checked">
    <div class="text-box">
      <label class="label_" for="checkBox" :data-checked="checked ? '' : '...'"></label>
      {{ text }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'textLimitRowShow',
  props: {
    text: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      checked: false
    }
  },
  methods: {
  }
}
</script>

<style lang="less" scoped>
.text-limit-row-show {
  width: 100%;
  display: flex;
  overflow: hidden;
}
.text-box {
  overflow: hidden;
  line-height: 1.5;
  height: 4.5em;
  position: relative;
  text-align: justify;
  transition: all 0.4s;
  padding-right: 5px;
}
.label_ {
  position: relative;
  float: right;
  clear: both;
  margin-left: 40px;
  margin-top: -6px;
  font-size: 24px;
  padding: 0px 0px;
  color: #367BF4;
  line-height: 24px;
  transition: all 0.4s;
}
.label_::after {
  content: '展开';
  font-size: 24px;
}
.check_box {
  display: none;
}
.text-box {
  font-size: 24px;
  color: rgba(0, 0, 0, 0.5);
}
.check_box:checked + .text-box {
  height: auto;
}
.check_box:checked + .text-box .label_::after {
  content: '收起';
}
.text-box::before {
  content: '';
  height: calc(100% - 24px);
  float: right;
}
.label_::before {
  content: attr(data-checked);
  position: absolute;
  left: -5px;
  color: #367BF4;
  transform: translate(-100%);
}
</style>