<template>
  <div class="left-lint-title">{{ text }}</div>
</template>

<script>
export default {
  name: 'leftLintTitle',
  props: {
    text: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="less" scoped>
.left-lint-title {
  font-size: 32px;
  line-height: 32px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 700;
  padding-left: 20px;
  position: relative;
  &::before {
    content: '';
    width: 8px;
    height: 28px;
    background-color: #E2E2E2;
    position: absolute;
    left: 0px;
    top: 0px;
    border-radius: 4px;
  }
}
</style>