<template>
  <div class="module-title">{{ text }}</div>
</template>

<script>
export default {
  name: 'moduleTitle',
  props: {
    text: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="less" scoped>
.module-title {
  box-sizing: border-box;
  display: inline-block;
  font-size: 32px;
  line-height: 32px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 700;
  padding: 0px 30px;
  position: relative;
  z-index: 1;
  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 16px;
    background-color: #E2E2E2;
    border-radius: 8px;
    left: 0px;
    bottom: -4px;
    z-index: -1;
  }
}
</style>