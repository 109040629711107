<template>
  <div class="course-detail">
    <div class="swipe-box">
      <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
        <van-swipe-item
          v-for="(item, index) in coursePictureUrlList"
          :key="'swipeimg' + index"
        >
          <img class="img" :src="item" />
        </van-swipe-item>
      </van-swipe>
    </div>
    <div class="course-title-info">
      <div class="title-box">
        <div class="title-text">{{ detail.courseName }}</div>
        <van-tag
          v-for="(item, index) in detail.fitApplyPersonList"
          :key="'sydx' + index"
          color="#E02020"
          size="large"
          plain
          style="background-color: rgba(224, 32, 32, 0.1)"
        >
          {{ fitApplyPersonMap[item] }}
        </van-tag>
        <van-tag
          v-if="courseTypeMap[detail.courseType]"
          color="#367BF4"
          size="large"
          plain
          style="background-color: rgba(54, 123, 244, 0.1)"
          >{{ courseTypeMap[detail.courseType] }}</van-tag
        >
      </div>
      <div class="course-price">¥{{ detail.price }}/整期</div>
    </div>
    <div class="teacher-introduce-box">
      <div class="avatar-box">
        <img
          v-if="detail.teacherPictureUrl"
          class="avatar-img"
          :src="detail.teacherPictureUrl"
        />
        <img v-else class="avatar-img" src="../img/no_avatar.png" />
      </div>
      <div class="name-introduce">
        <div class="name-text">{{ detail.teacher || "-" }}</div>
        <div class="introduce-text">
          <!-- <textLimitRowShow :text="detail.teacherDescription" /> -->
          {{ detail.teacherDescription }}
        </div>
      </div>
    </div>
    <module-title text="课程信息" />
    <div class="course-info">
      <div class="label-text-item">
        <div class="label-text">已约/满员：</div>
        <div v-if="detail.isAstrictPersons === 1" class="value-text">
          <span style="color: #367bf4">{{ detail.applyUserCount }}</span
          >人/{{ detail.maxPerson }}人&nbsp;&nbsp;{{ detail.minPerson }}人开班
        </div>
        <div v-else class="value-text">
          <span style="color: #367bf4">{{ detail.applyUserCount }}</span
          >人/不限人数
        </div>
      </div>
      <div class="label-text-item">
        <div class="label-text">开课时间：</div>
        <div class="value-text">
          <div
            v-for="(item, index) in detail.courseSettingList"
            :key="'kksj' + index"
          >
            {{ getDayWeekMonth(item) }}
          </div>
        </div>
      </div>
      <div class="label-text-item">
        <div class="label-text">开课周期：</div>
        <div class="value-text">
          {{ detail.courseOpenStart }} ~ {{ detail.courseOpenEnd }}
        </div>
      </div>
      <div class="label-text-item">
        <div class="label-text">报名时间：</div>
        <div class="value-text">
          {{ detail.applyDateStart.slice(0, 16) }} ~
          {{ detail.applyDateEnd.slice(0, 16) }}
        </div>
      </div>
      <div class="label-text-item">
        <div class="label-text">课程地点：</div>
        <div class="value-text">{{ detail.courseAddr }}</div>
      </div>
      <div class="label-text-item">
        <div class="label-text">课程管理员：</div>
        <div class="value-text">
          {{ detail.courseAdmin }}(<phone-number-show
            :phoneNumber="detail.courseAdminMobile"
          />)
        </div>
      </div>
      <left-lint-title text="课程介绍" />
      <div class="course-content">{{ detail.content }}</div>
    </div>
  </div>
</template>

<script>
import mrtp from "../img/kctp_zw_lb.png";
import { fitApplyPersonMap, dayWeekMonthMap, getDayWeekMonth } from "../map.js";
import textLimitRowShow from "@/components/bussiness/textLimitRowShow.vue";
import moduleTitle from "@/components/bussiness/moduleTitle.vue";
import leftLintTitle from "@/components/bussiness/leftLintTitle.vue";
import phoneNumberShow from "@/components/bussiness/phoneNumberShow.vue";
import Vue from "vue";
import { Tag, Swipe, SwipeItem } from "vant";

Vue.use(Tag);
Vue.use(Swipe);
Vue.use(SwipeItem);

export default {
  name: "coursesDetail",
  components: {
    // textLimitRowShow,
    moduleTitle,
    leftLintTitle,
    phoneNumberShow,
  },
  props: {
    detail: {
      type: Object,
      default: () => ({
        coursePictureUrlList: [],
        fitApplyPersonList: [],
        courseSettingList: [],
      }),
    },
    courseTypeMap: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      fitApplyPersonMap,
      dayWeekMonthMap,
    };
  },
  computed: {
    coursePictureUrlList() {
      if (
        Array.isArray(this.detail.coursePictureUrlList) &&
        this.detail.coursePictureUrlList.length > 0
      ) {
        return this.detail.coursePictureUrlList;
      } else {
        return [mrtp];
      }
    },
  },
  created() {},
  mounted() {},
  methods: {
    getDayWeekMonth,
  },
};
</script>

<style lang="less" scoped>
.course-detail {
  box-sizing: border-box;
  padding: 0px;
}
.swipe-box {
  margin: 0px 30px;
  border-radius: 16px;
  height: 330px;
  box-shadow: 0px 4px 32px 0px rgba(66, 114, 153, 0.2);
  overflow: hidden;
  .img {
    width: 100%;
    height: 330px;
    border-radius: 16px;
  }
}
.course-title-info {
  box-sizing: border-box;
  width: calc(100% - 60px);
  margin: 30px 30px 0px;
  border-radius: 16px;
  box-shadow: 0px 4px 32px 0px rgba(66, 114, 153, 0.2);
  padding: 28px;
  display: flex;
  align-items: center;
  .title-box {
    box-sizing: border-box;
    flex-grow: 1;
    .title-text {
      font-size: 32px;
      line-height: 44px;
      color: rgba(0, 0, 0, 0.85);
      font-weight: 700;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 10;
      -webkit-box-orient: vertical;
      word-break: break-all;
    }
    .van-tag {
      margin-right: 10px;
      margin-top: 10px;
    }
  }
  .course-price {
    flex-shrink: 0;
    font-size: 32px;
    line-height: 34px;
    color: #d9021b;
    margin-left: 15px;
  }
}
.teacher-introduce-box {
  box-sizing: border-box;
  width: calc(100% - 60px);
  margin: 30px 30px 0px;
  border-radius: 16px;
  box-shadow: 0px 4px 32px 0px rgba(66, 114, 153, 0.2);
  padding: 20px 22px;
  display: flex;
  .avatar-box {
    flex-shrink: 0;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 18px;
    .avatar-img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
    }
  }
  .name-introduce {
    flex-grow: 1;
    .name-text {
      font-size: 28px;
      line-height: 34px;
      font-weight: 700;
      color: rgba(0, 0, 0, 0.8);
    }
    .introduce-text {
      font-size: 24px;
    }
  }
}
.course-info {
  box-sizing: border-box;
  width: calc(100% - 60px);
  margin: 20px 30px 0px 30px;
  border-radius: 16px;
  box-shadow: 0px 4px 32px 0px rgba(66, 114, 153, 0.2);
  padding: 22px 30px;
  .label-text-item {
    display: flex;
    margin-bottom: 14px;
    .label-text {
      flex-shrink: 0;
      width: 162px;
      font-size: 26px;
      line-height: 36px;
      color: rgba(0, 0, 0, 0.85);
    }
    .value-text {
      flex-grow: 1;
      font-size: 26px;
      line-height: 36px;
      color: rgba(0, 0, 0, 0.5);
    }
  }
  .left-lint-title {
    margin-top: 30px;
  }
  .course-content {
    font-size: 26px;
    line-height: 36px;
    color: rgba(0, 0, 0, 0.85);
    margin-top: 15px;
  }
}
.footer-button {
  box-sizing: border-box;
  width: 100%;
  height: 100px;
  padding: 16px 30px;
  position: fixed;
  left: 0px;
  bottom: 0px;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #ccc;
  z-index: 9;
  .hangdle-button {
    width: 80%;
    height: 68px;
    background: linear-gradient(180deg, #73a8ff 0%, #2e7bff 100%);
    box-shadow: 0px 4px 8px 0px rgba(225, 225, 225, 0.5);
    border-radius: 10px;
    font-size: 30px;
    line-height: 30px;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    &.disablet-btn {
      background: linear-gradient(180deg, #ededed 0%, #d8d8d8 100%);
      color: #a8a8a8;
    }
  }
}
.tel-a {
  color: #367bf4;
}

.module-title {
  margin: 30px 30px 0px 30px;
}
</style>
