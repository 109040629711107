<template>
  <a v-show="phoneNumber" class="tel-a" :href="'tel:' + phoneNumber" @click.stop>{{ phoneNumber }}</a>
</template>

<script>
export default {
  name: 'phoneNumberShow',
  props: {
    phoneNumber: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="less" scoped>
.tel-a {
  color: #367BF4;
}
</style>
